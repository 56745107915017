var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm.api.isLoading
                  ? _c("v-skeleton-loader", {
                      ref: "skeleton",
                      attrs: { type: "table" },
                    })
                  : _vm._e(),
                this.companiesNew != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        items: this.filteredCompanies,
                        headers: this.companiesNewHeader,
                        search: _vm.searchCompanies,
                        dense: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  { attrs: { flat: "", height: "auto" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "ma-4" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-toolbar-title",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-h4 my-3",
                                                  },
                                                  [_vm._v(" New Renewal ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { staticClass: "mt-5" },
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        label: "Search...",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchCompanies,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.searchCompanies =
                                                            $$v
                                                        },
                                                        expression:
                                                          "searchCompanies",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("v-col", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {},
                                                        [
                                                          _c("v-text-field", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "4px 0px 0px 4px",
                                                            },
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              label:
                                                                "Start Date...",
                                                            },
                                                            on: {
                                                              "click:prepend-inner":
                                                                function () {
                                                                  _vm.isStartDate = true
                                                                },
                                                              click:
                                                                function () {
                                                                  _vm.isStartDate = true
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.startDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.startDate =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "startDate",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                height: "auto",
                                                                width: "auto",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isStartDate,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.isStartDate =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "isStartDate",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " Start Date "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  landscape: true,
                                                                                  reactive: true,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.startDate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.startDate =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "startDate",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._t(
                                                                            "footer",
                                                                            function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex justify-end white",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                            plain:
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function () {
                                                                                              _vm.isStartDate = false
                                                                                              _vm.startDate =
                                                                                                null
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Cancel "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function () {
                                                                                              _vm.isStartDate = false
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Confirm "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("v-text-field", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              dense: "",
                                                              outlined: "",
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              label:
                                                                "End Date...",
                                                            },
                                                            on: {
                                                              "click:prepend-inner":
                                                                function () {
                                                                  _vm.isEndDate = true
                                                                },
                                                              click:
                                                                function () {
                                                                  _vm.isEndDate = true
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.endDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.endDate =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "endDate",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                height: "auto",
                                                                width: "auto",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.isEndDate,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.isEndDate =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "isEndDate",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          " End Date "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  landscape: true,
                                                                                  reactive: true,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.endDate,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.endDate =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "endDate",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      attrs: {
                                                                        name: "footer",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex justify-end my-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                  plain:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function () {
                                                                                    _vm.isEndDate = false
                                                                                    _vm.endDate =
                                                                                      null
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Cancel "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function () {
                                                                                    _vm.isEndDate = false
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Confirm "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticStyle: {
                                                                "border-radius":
                                                                  "0px 4px 4px 0px",
                                                                height: "40px",
                                                              },
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.filterDate,
                                                              },
                                                            },
                                                            [_vm._v(" Filter ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticClass:
                                                  "d-flex justify-end my-62",
                                              },
                                              [
                                                _vm._v(" ` "),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "mx-2",
                                                    attrs: {
                                                      color: "primary",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      click: _vm.resetFilter,
                                                    },
                                                  },
                                                  [_vm._v(" Reset Filter ")]
                                                ),
                                                _c(
                                                  "JsonCSV",
                                                  {
                                                    attrs: {
                                                      data: _vm.downloadCompanies,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v(" Download ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" ` "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.company_name",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "PageCompaniesDetail",
                                        params: { id: item.company_id },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.company_name) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.Index",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.filteredCompanies.indexOf(item) + 1
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.new_joiner",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.new_joiner != null
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.new_joiner)
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }